#wrapper
{
     overflow-x:hidden;
     overflow-y:hidden;
}

.map-marker{   
     font-weight: bold;
 }

#imgcard_estacion{
     margin-top: 5px;
     font-size: small;
}

#textcard_estacion{    
     font-size: small;
}

#stokcard_estacion{
     margin-top: 10px;
     text-align:left ;
}

#avatar_user{
     border-radius: 100em;
     border: 2px solid #0BA3EC;
     
}

#btn_cambiar_img{
     font-size: small;
     background-color:#ffffff;    
     border: 0px;
 }

 .material-symbols-outlined.md-18 { font-size: 18px; }
 .material-symbols-outlined.md-24 { font-size: 24px; }
 .material-symbols-outlined.md-36 { font-size: 36px; }
 .material-symbols-outlined.md-48 { font-size: 48px; }
 .material-symbols-outlined.md-50 { font-size: 50px; }

 .profile-img {
     width: 110px; /* alto y ancho específico para que el círculo sea redondo */
     height: 130px;
     border-radius: 50%; 
     overflow: hidden; /* oculta todo el contenido fuera de la caja */
     position: relative;
   }
   
   .profile-img img {
     position: absolute;
     width: 100%; /* Soluciona el problema de ubicación de las imágenes en cajas circulares */
     left: 0%; /* posiciona al centro del contenedor la imagen */
   }
   
   .profile-img .file {
     position: absolute;
     width: 100%;
     height: 20%;
     bottom: 0;
     font-size: 11px;
     background: #5a6066b8;
     color: #fff;
     text-align: center;
     padding: 5px;
    
   }
   
   .profile-img .file input {
     position: absolute;
     display: block;
     width: 100%;
     opacity: 0;
     top: 0;
     left: 0;
     bottom: 0;
     cursor: pointer; /* manita de selección al hacer hover */
   }
 

