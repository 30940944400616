#sidebar{
   
        background-color: #015CAC;
        color:rgb(255, 255, 255);   
  
}

#nav-header{
        background-color: #ffffff;
        color:black;       
}

#texto_nameuser:hover{

 color: #ffffff;
}


.brand-text{
    color:rgb(255, 255, 255);
}


.d-block{
    color:rgb(255, 255, 255);

}

.sidebar{
        background-color:#015CAC;
        height: 100%;
        
    }
    
    .sidebar li i,
    .sidebar li p{
        color: #f5f5f5;
       
    }
    
    .sidebar li {
        transition: 0.7s;
        transition-property:background-color ;    
      }
    
    .sidebar li:hover {
        background-color: #0BA3EC;
        text-decoration: none;
        color:#fff;    
        border-radius: 5px;
    }

    #texto_final{
        font-size: small;
    
    }
