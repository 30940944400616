#body_login{
    background-image: url(../Imagenes/fondoportal.png);
    background-size: cover;
    background-repeat: no-repeat;
    margin: 10;
    height: 100vh;
  
    overflow-x: hidden;
  }
  

#footer {
   
    left: 0;
    bottom: 0;
    width: 100%;
    color: rgb(0, 0, 0);
    text-align: center;
}

#footer_404 {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: rgb(0, 0, 0);
    text-align: center;
}


#card-login{
    border-radius: 10%;
    height: 100vh;  

}

#imge_logo{
  margin-top: 6%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#id_tipoid,
#idenificacion_user,
#pass_1{
   
    border-top-color:#ffffff;
    border-right-color:#ffffff;
    border-left-color:#ffffff;
    border-bottom-color:#015CAC
}

.input-group-text{
    border:0;
    background-color: #ffffff;
}


#btn_registrarse{
    background-color: #5da839 ;
    border-color: #5da839;
    width: 70%;
    border-radius: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
}

#btn_registrarse:hover{
    background-color: #3ba30e ;
    border-color: none;
    width: 70%;
    border-radius: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#text-registrarse{
    color: #015CAC;
    font-weight: bold;
}

#msj_registro{
    color: #0BA3EC;
    font-size: small;
}

#copyright{
    color: #f1f2f3;
}

#body_login
{
     overflow-x:hidden;
     overflow-y:hidden;
}

#checkpass{
  color: #015CAC;
  font-size: 11px;
  text-decoration-line: underline;
}

#checkpass:hover{
    color: #015CAC;
    text-decoration-line: underline;
  }